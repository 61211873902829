import type { ConversationType, ItemRow } from 'owa-graph-schema';
import { store } from '../store/store';
import type { MailListRowDataType } from 'owa-mail-list-store';
import { convertRestIdToEwsId } from 'owa-identifiers';
import { logUsage } from 'owa-analytics';

/**
 * Filters email rows for item view.
 * Returns only those rows whose ItemId matches an ItemId returned by the experimentAPI
 */
export const filterEmailsWithScheduleTasksForItemView = (
    rows: ItemRow[]
): MailListRowDataType[] => {
    const itemsIdsWithSchedulingTasks = store.itemsIdsWithSchedulingTasks;
    if (itemsIdsWithSchedulingTasks.length === 0) {
        logUsage('CopilotDigestScheduleFilterRowCount', { count: 0, view: 'item' });
        return [];
    }

    const filteredRows = rows.filter(row => {
        if (!row.ItemId?.Id) {
            return false;
        }
        return itemsIdsWithSchedulingTasks.some(id => convertRestIdToEwsId(id) === row.ItemId.Id);
    }) as MailListRowDataType[];

    logUsage('CopilotDigestScheduleFilterRowCount', { count: filteredRows.length, view: 'item' });
    return filteredRows;
};

/**
 * Filters conversation threads for conversation view.
 * Returns only those conversations where at least one of multiple ItemIds matches an ID returned by the experimentAPI.
 */
export const filterEmailsWithScheduleTasksForConversationView = (
    rows: ConversationType[]
): MailListRowDataType[] => {
    const itemsIdsWithSchedulingTasks = store.itemsIdsWithSchedulingTasks;
    if (itemsIdsWithSchedulingTasks.length === 0) {
        logUsage('CopilotDigestScheduleFilterRowCount', { count: 0, view: 'conversation' });
        return [];
    }

    const filteredRows = rows.filter(conversation => {
        const threadMessageIds = conversation.ItemIds as {
            Id: string;
        }[];
        if (!threadMessageIds?.length) {
            return false;
        }

        return itemsIdsWithSchedulingTasks.some(schedulingTaskId =>
            threadMessageIds.some(
                messageId => convertRestIdToEwsId(schedulingTaskId) === messageId.Id
            )
        );
    }) as MailListRowDataType[];

    logUsage('CopilotDigestScheduleFilterRowCount', {
        count: filteredRows.length,
        view: 'conversation',
    });
    return filteredRows;
};
